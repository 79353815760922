import { useEffect, useState, useRef } from 'react';
import { Table, Button, Modal, InputNumber, message, Tag } from 'antd';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import { tableDistributions } from './table-Structure/Distributions';
import ImportShareModal from './ImportShareModal';

export default function Distributions({
  distributions,
  offersData,
  investmentId,
  isLoading,
  updateShare,
  handleDowndloadCSV,
  updateShareImport,
}) {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shares, setShares] = useState([]);
  const [success, setSuccess] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImportVisible, setModalImportVisible] = useState(false);
  const [totalShare, setTotalShare] = useState(0);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const formImport = useRef();

  useEffect(() => {
    setLoading(true);
    setOffers([...offersData]);
    setLoading(false);
  }, [offersData]);

  useEffect(() => {
    const totalWrapped = offers.reduce(
      (acc, offer) => (offer?.share ? acc + offer.share : acc + 0),
      0
    );
    const total = totalWrapped.toFixed(4);
    setTotalShare(total);
  }, [offers]);

  const handleShareOnChange = (id, value) => {
    const share = shares.find((s) => s._id === id);
    if (share !== undefined) {
      const allShare = shares.filter((s) => s._id !== id);
      share.share = value;
      setShares([...allShare, share]);
      addShareInTable([...allShare, share]);
    } else {
      setShares([...shares, { _id: id, share: value }]);
      addShareInTable([...shares, { _id: id, share: value }]);
    }
  };

  const addShareInTable = (shares) => {
    const offersWithShare = offers.map((offer) => {
      const offerShare = shares.filter((share) => share._id === offer._id);
      return {
        ...offer,
        share: offerShare[0]?.share || offer?.share,
      };
    });
    setOffers(offersWithShare);
  };

  const updateShareOfOffers = async () => {
    const offersWithShare = offers.filter((offer) => offer?.share);
    if (offersWithShare.length !== offers.length) {
      setSuccess(false);
      return message.error(
        'Complete todos los campos antes de Guardar los cambios.'
      );
    }
    if (totalShare > 100) {
      setSuccess(false);
      return message.error('El share no puede ser mayor al 100%.');
    }
    if (totalShare < 100) {
      setSuccess(false);
      return message.error('El share no puede ser menor al 100%.');
    }
    setLoading(true);
    const payload = offers.map((offer) => {
      return { _id: offer._id, share: offer.share };
    });
    await updateShare(payload);
    setSuccess(true);
    setLoading(false);
    setModalVisible(false);
  };

  const columns = [
    {
      title: 'Fecha de Creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      enable: true,
      render: (createdAt, record) => (
        <>
          <span>{dateFormat(createdAt)}</span>
          {record.createdManually && (
            <Tag color="blue" className="ms-2">
              Manual
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Inversor',
      dataIndex: 'user',
      key: 'user',
      enable: true,
      render: (user) => `${user?.firstName} ${user?.lastName}`,
    },
    {
      title: 'Monto Invertido',
      dataIndex: 'amountOffered',
      key: 'amountOffered',
      enable: true,
      render: (a) => (
        <p className="text-success">
          <b>{`$${a}`}</b>
        </p>
      ),
    },
    {
      title: 'Share',
      dataIndex: ['share', '_id'],
      key: 'share',
      enable: true,
      render: (text, row) => (
        <InputNumber
          className={
            success
              ? 'text-success border-success'
              : success === false && 'text-danger border-danger'
          }
          value={row['share'] && row['share']}
          max={100}
          parser={(value) => {
            const percentage = value.replace('%', '');
            const decimal = percentage.replace(',', '.');
            const limitedDecimal = decimal.match(/^-?\d+(?:\.\d{0,4})?/);
            return parseFloat(limitedDecimal);
          }}
          maxLength={7}
          name={row['_id']}
          onChange={(e) => handleShareOnChange(row['_id'], e)}
        />
      ),
    },
  ];

  const clearUploadedFile = () => {
    formImport.current.resetFields(['importadorShareExcel']);
    setDefaultFileList([]);
  };

  const onImportFinish = async (values) => {
    setSubmitting(true);
    const file = values?.importadorShareExcel?.file;
    if (file) {
      const fileName = file.name.toLowerCase();

      if (
        fileName.endsWith('.xlsx') ||
        fileName.endsWith('.xls') ||
        fileName.endsWith('.csv')
      ) {
        const formData = new FormData();
        formData.append('excelFile', file);

        await updateShareImport(formData);
        setModalImportVisible(false);
        clearUploadedFile();
      } else {
        errorMessage('El archivo debe ser de tipo Excel (xlsx, xls) o CSV', 0);
      }
    } else {
      errorMessage('No se ha seleccionado ningún archivo', 0);
    }

    setSubmitting(false);
  };

  const onImportFinishFailed = () => {
    errorMessage('Hubo un error al procesar el archivo', 0);
  };

  return (
    <div>
      <>
        <Button onClick={() => setModalVisible(true)}>Definir share</Button>
        <p className="btn-closeInv-filter">Total: {distributions.length}</p>

        <Table
          dataSource={distributions}
          columns={tableDistributions()}
          scroll={{ x: 1000 }}
          loading={isLoading}
          pagination={false}
        ></Table>
      </>
      <Modal
        title={
          <>
            <span>Definir share</span>
            <div className="btn-closeInv-filter me-5">
              <Button onClick={() => setModalImportVisible(true)}>
                Importar Share
              </Button>
              <Button onClick={handleDowndloadCSV}>Descargar CSV</Button>

              <span> - Total share: {totalShare}%</span>
            </div>

            <ImportShareModal
              modalImportVisible={modalImportVisible}
              setModalImportVisible={setModalImportVisible}
              formImport={formImport}
              onImportFinish={onImportFinish}
              onImportFinishFailed={onImportFinishFailed}
              defaultFileList={defaultFileList}
              setDefaultFileList={setDefaultFileList}
              clearUploadedFile={clearUploadedFile}
              submitting={submitting}
            />
          </>
        }
        className="mt-5"
        centered
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={false}
        width={1000}
        bodyStyle={{ maxHeight: '100%', paddingBottom: 60 }}
      >
        <Table
          dataSource={offers}
          columns={columns}
          loading={loading}
          pagination={false}
          scroll={{ x: 900 }}
        />
        <div className="mt-3">
          <Button className="btn-closeInv-filter" onClick={updateShareOfOffers}>
            Guardar cambios
          </Button>
        </div>
      </Modal>
    </div>
  );
}
