import { InputNumber } from 'antd';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';

export const tableNewDistributions = (handleShareOnChange, handleRentsOnChange, success, calcRentCompleted) => [
  {
    title: 'Fecha de Creación',
    dataIndex: 'createdAt',
    key: 'createdAt',
    enable: true,
    render: (createdAt) => dateFormat(createdAt),
  },
  {
    title: 'Inversor',
    dataIndex: 'user',
    key: 'user',
    enable: true,
    render: (user) => `${user?.firstName} ${user?.lastName}`,
  },
  {
    title: 'Monto Invertido',
    dataIndex: 'amountOffered',
    key: 'amountOffered',
    enable: true,
    render: (a) => (
      <p className="text-success">
        <b>{`$${a}`}</b>
      </p>
    ),
  },
  {
    title: 'Share',
    width: '13%',
    dataIndex: ['share', '_id'],
    key: 'share',
    enable: true,
    render: (text, row) => (
      <InputNumber
        className={
          success
            ? 'text-success border-success'
            : success === false && 'text-danger border-danger'
        }
        value={row['share']}
        max={100}
        disabled
        parser={(value) => {
          const percentage = value.replace('%', '');
          const decimal = percentage.replace(',', '.');
          const limitedDecimal = decimal.match(/^-?\d+(?:\.\d{0,4})?/);
          return parseFloat(limitedDecimal);
        }}
        maxLength={7}
        name={row['_id']}
        onChange={(e) => handleShareOnChange(row['_id'], e)}
      />
    ),
  },
  {
    title: 'Renta',
    width: '12%',
    dataIndex: ['rent', '_id'],
    key: 'rent',
    enable: true,
    render: (text, row) => (
      <InputNumber
        className={
          success
            ? 'text-success border-success'
            : success === false && 'text-danger border-danger'
        }
        disabled
        name={row['_id']}
        value={row['rent']}
        onChange={(e) => handleRentsOnChange(row['_id'], e)}
      />
    ),
  },
];
